<template>
    <div class="form-group textAreaWithLimit" :class="wrapperClass">
        <textarea
            ref="textarea"
            v-bind="$attrs"
            class="form-control"
            :value="modelValue"
            :minlength="minlengthValue"
            :maxlength="maxlength"
            @input="update"
        />
        <div v-show="showLimits" class="@flex @text-sfc-medium-grey l10 @py-[3px]">
            <span v-if="(+minlength)" class="@lowercase charsRequired">{{minlength}} characters required</span>
            <span class="@ml-auto charsMax">{{modelValue?.length || 0}} / {{maxlength}}</span>
        </div>
    </div>
</template>

<script lang="ts" setup>
    import {ref, computed, onMounted, onBeforeUnmount} from "vue";
    import {debounce} from "lodash-es";

    defineOptions({
        inheritAttrs: false
    });

    const props = defineProps({
        modelValue: {type: String, default: ""},
        maxlength: {type: [Number, String], default: null},
        minlength: {type: [Number, String], default: 0},
        wrapperClass: {type: String, default: ""},
        fitContent: {type: Boolean, default: true},
        showLimits: {type: Boolean, default: true}
    });

    const emit = defineEmits(["update:modelValue"]);

    const debounceFunc = ref();
    const textarea = ref();

    const minlengthValue = computed(()=> {
        return props.modelValue ? props.minlength : undefined;
    });

    const resizeToFit = () => {
        if (props.fitContent && textarea.value) {
            const textareaRef = textarea.value;
            textareaRef.style.height = "";
            textareaRef.style.height = (textareaRef.scrollHeight + 5) + "px";
        }
    };

    const update = (e: Event) => {
        textarea.value?.setCustomValidity("");
        resizeToFit();
        emit("update:modelValue", (e.target as HTMLTextAreaElement).value);
    };

    onMounted(() => {
        if (props.modelValue && props.minlength > props.modelValue.length) {
            textarea.value?.setCustomValidity(`${props.minlength} characters required`);
        }
        resizeToFit();
        debounceFunc.value = debounce(resizeToFit, 500);
        window.addEventListener("resize", debounceFunc.value);
    });

    onBeforeUnmount(() => {
        window.removeEventListener("resize", debounceFunc.value);
    });
</script>
